import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate from 'validate.js';
// import { LearnMoreLink } from 'components/atoms';
import { resetPassword } from 'util/idm';
import LearnMoreLink from 'components/atoms/LearnMoreLink';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
    },
  },
  password_confirmation: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
    },
  },
};


const Form = (): JSX.Element => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [serverError, setServerError] = useState();

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {
      const urlParams = new URLSearchParams(window.location.search);

      return resetPassword({
        resetPasswordToken: urlParams.get('reset_password_token'),
        password: formState.values.password,
        passwordConfirmation: formState.values.password_confirmation,
      }).then(response => {
        setShowSuccess(true)

        formState.values = {}
        setFormState(formState => ({
          ...formState,
          touched: {
            ...formState.touched,
            ...formState.errors,
          },
        }));
      }).catch(error => {
        if (error.response.status == 422 || error.response.status == 401) {
          formState.errors = error.response.data.errors

          setFormState(formState => ({
            ...formState,
            touched: {
              ...formState.touched,
              ...formState.errors,
            },
          }));
        } else {
          throw error;
        }
      });
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {showSuccess &&
            <Grid item xs={12}>
              <Typography paragraph>
                Your password has been reset.
              </Typography>
            </Grid>}
          {/* TODO Show password tiken invalid error here */}

          {hasError('reset_password_token') &&
            <Grid item xs={12}>
              <Typography paragraph color="error">
                This password rest link is invalid
              </Typography>
            </Grid>}

          <Grid item xs={12}>
            <TextField
              placeholder="Password"
              label="Password *"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              error={hasError('password')}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              placeholder="Password Confirmation"
              label="Password Confirmation *"
              variant="outlined"
              size="medium"
              name="password_confirmation"
              fullWidth
              helperText={
                hasError('password_confirmation') ? formState.errors.password_confirmation[0] : null
              }
              error={hasError('password_confirmation')}
              onChange={handleChange}
              type="password"
              value={formState.values.password_confirmation || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Change Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Remember your password?{' '}
              <LearnMoreLink title="Sign in here" href="/sign-in" />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
